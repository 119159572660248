<template>
  <div id="app">
    <MyHeaderVue />
    <router-view></router-view>
    <MyFooterVue />
  </div>
</template>

<script>
// import MyHeaderVue from './page/chatchaimobile/MyHeader.vue'
// import MyFooterVue from './page/chatchaimobile/MyFooter.vue'

//jaidee官网
// import MyHeaderVue from './page/jaidee/MyHeader.vue'
// import MyFooterVue from './page/jaidee/MyFooter.vue'

//jaideeconnect
// import MyHeaderVue from './page/jaideeconnect/MyHeader.vue'
// import MyFooterVue from './page/jaideeconnect/MyFooter.vue'



//越南kasikvay
// import MyHeaderVue from './page/kasikvay/MyHeader.vue'
// import MyFooterVue from './page/kasikvay/MyFooter.vue'

//越南tamogiefectivo
// import MyHeaderVue from './page/tamogiefectivo/MyHeader.vue'
// import MyFooterVue from './page/tamogiefectivo/MyFooter.vue'

//墨西哥consedeefectivo
// import MyHeaderVue from './page/consedeefectivo/MyHeader.vue'
// import MyFooterVue from './page/consedeefectivo/MyFooter.vue'

//越南nathcopay
// import MyHeaderVue from './page/components/MyHeader5.vue'
// import MyFooterVue from './page/components/MyFooter3.vue'

//越南mrVaymobile
// import MyHeaderVue from './page/mrVaymobile/MyHeader.vue'
// import MyFooterVue from './page/mrVaymobile/MyFooter.vue'


//越南Hcthlog Plus
// import MyHeaderVue from './page/hcthlogplus/MyHeader.vue'
// import MyFooterVue from './page/hcthlogplus/MyFooter.vue'

//墨西哥hipotecariabilletera
// import MyHeaderVue from './page/hipotecariabilletera/MyHeader.vue'
// import MyFooterVue from './page/hipotecariabilletera/MyFooter.vue'

//越南sunmobile
// import MyHeaderVue from './page/sunmobile/MyHeader.vue'
// import MyFooterVue from './page/sunmobile/MyFooter.vue'

//越南cathaydonge
// import MyHeaderVue from './page/cathaydong/MyHeader.vue'
// import MyFooterVue from './page/cathaydong/MyFooter.vue'


//越南newvay
// import MyHeaderVue from './page/newvay/MyHeader.vue'
// import MyFooterVue from './page/newvay/MyFooter.vue'

//墨西哥eldefiardinero
// import MyHeaderVue from './page/eldefiardinero/MyHeader.vue'
// import MyFooterVue from './page/eldefiardinero/MyFooter.vue'

//越南thanhchungplus
// import MyHeaderVue from './page/thanhchungplus/MyHeader.vue'
// import MyFooterVue from './page/thanhchungplus/MyFooter.vue'

//墨西哥dealcorpefectivo
// import MyHeaderVue from './page/dealcorpefectivo/MyHeader.vue'
// import MyFooterVue from './page/dealcorpefectivo/MyFooter.vue'

//墨西哥kampalacash
// import MyHeaderVue from './page/kampalacash/MyHeader.vue'
// import MyFooterVue from './page/kampalacash/MyFooter.vue'

//泰国pheera
// import MyHeaderVue from './page/pheera/MyHeader.vue'
// import MyFooterVue from './page/pheera/MyFooter.vue'

//越南piwbplus
// import MyHeaderVue from './page/piwebplus/MyHeader.vue'
// import MyFooterVue from './page/piwebplus/MyFooter.vue'

//越南piwebplus
// import MyHeaderVue from './page/piwebplus2/MyHeader.vue'
// import MyFooterVue from './page/piwebplus2/MyFooter.vue'

//墨西哥directodoefectivo
// import MyHeaderVue from './page/directodoefectivo/MyHeader.vue'
// import MyFooterVue from './page/directodoefectivo/MyFooter.vue'

//泰国Jaideeonline
// import MyHeaderVue from './page/Jaideeonline/MyHeader.vue'
// import MyFooterVue from './page/Jaideeonline/MyFooter.vue'

//墨西哥braskemefectivo
// import MyHeaderVue from './page/braskemefectivo/MyHeader.vue'
// import MyFooterVue from './page/braskemefectivo/MyFooter.vue'


//墨西哥dealcorpefectivo2
// import MyHeaderVue from './page/dealcorpefectivo2/MyHeader.vue'
// import MyFooterVue from './page/dealcorpefectivo2/MyFooter.vue'

//墨西哥ceibamóvil
// import MyHeaderVue from './page/ceibamóvil/MyHeader.vue'
// import MyFooterVue from './page/ceibamóvil/MyFooter.vue'

//墨西哥estimulocrédito
// import MyHeaderVue from './page/estimulocrédito/MyHeader.vue'
// import MyFooterVue from './page/estimulocrédito/MyFooter.vue'

//墨西哥cincodospréstamos
// import MyHeaderVue from './page/cincodospréstamos/MyHeader.vue'
// import MyFooterVue from './page/cincodospréstamos/MyFooter.vue'

//墨西哥concreecefectivo
// import MyHeaderVue from './page/concreecefectivo/MyHeader.vue'
// import MyFooterVue from './page/concreecefectivo/MyFooter.vue'


//墨西哥daraptipago
// import MyHeaderVue from './page/daraptipago/MyHeader.vue'
// import MyFooterVue from './page/daraptipago/MyFooter.vue'

//泰国microfinmobile
// import MyHeaderVue from './page/microfinmobile/MyHeader.vue'
// import MyFooterVue from './page/microfinmobile/MyFooter.vue'


//墨西哥ammóvil
// import MyHeaderVue from './page/ammóvil/MyHeader.vue'
// import MyFooterVue from './page/ammóvil/MyFooter.vue'

//墨西哥ngernsaen
// import MyHeaderVue from './page/ngernsaen/MyHeader.vue'
// import MyFooterVue from './page/ngernsaen/MyFooter.vue'


//泰国domcap
// import MyHeaderVue from './page/domcap/MyHeader.vue'
// import MyFooterVue from './page/domcap/MyFooter.vue'


//墨西哥cherreypréstamos2
// import MyHeaderVue from './page/cherreypréstamos2/MyHeader.vue'
// import MyFooterVue from './page/cherreypréstamos2/MyFooter.vue'

//墨西哥facicasapresta
// import MyHeaderVue from './page/facicasapresta/MyHeader.vue'
// import MyFooterVue from './page/facicasapresta/MyFooter.vue'

//墨西哥eliteloan
// import MyHeaderVue from './page/eliteloan/MyHeader.vue'
// import MyFooterVue from './page/eliteloan/MyFooter.vue'

//越南drkinhtiềnbạc
// import MyHeaderVue from './page/drkinhtiềnbạc/MyHeader.vue'
// import MyFooterVue from './page/drkinhtiềnbạc/MyFooter.vue'


//越南petrolivay
// import MyHeaderVue from './page/petrolivay/MyHeader.vue'
// import MyFooterVue from './page/petrolivay/MyFooter.vue'

//越南lestardong
// import MyHeaderVue from './page/lestardong/MyHeader.vue'
// import MyFooterVue from './page/lestardong/MyFooter.vue'

//越南sunvay
import MyHeaderVue from './page/sunvay/MyHeader.vue'
import MyFooterVue from './page/sunvay/MyFooter.vue'

//墨西哥paratidinero
// import MyHeaderVue from './page/paratidinero/MyHeader.vue'
// import MyFooterVue from './page/paratidinero/MyFooter.vue'


export default {
  name: "App",
  components: {
    MyHeaderVue,
    MyFooterVue,
  },
};
</script>

<style>
#app {
  color: #2c3e50;
}
</style>
